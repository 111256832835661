<script>
export default {
  name: "Terms",
};
</script>

<template>
  <div class="main-doc">
    <div class="_container">
      <div class="main-doc__title">Пользовательское Соглашение</div>
      <div class="main-doc__body">
        <div class="main-doc__paragraph">
          <div class="main-doc__content">
            Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует
            отношения между РМОО «Лига студентов РТ» (далее Лига студентов
            Республики Татарстан или Администрация) с одной стороны и
            пользователем сайта с другой. сайт Лига студентов Республики
            Татарстан не является средством массовой информации. Используя сайт,
            вы соглашаетесь с условиями данного соглашения. Если вы не согласны
            с условиями данного соглашения, не используйте сайт Лига студентов
            Республики Татарстан!
          </div>
        </div>
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">Предмет соглашения</div>
          <div class="main-doc__content">
            Администрация предоставляет пользователю право на размещение на
            сайте следующей информации:

            <ul>
              <li>— Текстовой информации</li>
              <li>— Фотоматериалов</li>
              <li>— Ссылок на материалы, размещённые на других сайтах</li>
            </ul>
          </div>
        </div>
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">Права и обязанности сторон</div>
          <div class="main-doc__content bold">
            <ul>
              <li>
                Пользователь имеет право:

                <ul>
                  <li>— осуществлять поиск информации на сайте</li>
                  <li>— получать информацию на сайте</li>
                  <li>
                    — использовать информацию сайта в личных некоммерческих
                    целях
                  </li>
                </ul>
              </li>
              <li>
                Администрация имеет право:
                <ul>
                  <li>
                    — по своему усмотрению и необходимости создавать, изменять,
                    отменять правила
                  </li>
                  <li>— ограничивать доступ к любой информации на сайте</li>
                  <li>— создавать, изменять, удалять информацию</li>
                </ul>
              </li>
              <li>
                Пользователь обязуется:
                <ul>
                  <li>— обеспечить достоверность предоставляемой информации</li>
                  <li>
                    — обеспечивать сохранность личных данных от доступа третьих
                    лиц
                  </li>
                  <li>
                    — обновлять Персональные данные, предоставленные при
                    регистрации, в случае их изменения
                  </li>
                  <li>— не нарушать работоспособность сайта</li>
                  <li>
                    — не передавать в пользование свою учётную запись и/или
                    логин и пароль своей учётной записи третьим лицам
                  </li>
                  <li>
                    — не использовать скрипты (программы) для
                    автоматизированного сбора информации и/или взаимодействия с
                    сайтом и его Сервисами
                  </li>
                </ul>
              </li>
              <li>
                Администрация обязуется:
                <ul>
                  <li>
                    — поддерживать работоспособность сайта за исключением
                    случаев, когда это невозможно по независящим от
                    Администрации причинам
                  </li>
                  <li>
                    — осуществлять разностороннюю защиту учётной записи
                    Пользователя
                  </li>
                  <li>
                    — защищать информацию, распространение которой ограничено
                    или запрещено законами путём вынесения предупреждения либо
                    удалением учётной записи пользователя, нарушившего правила
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">Ответственность сторон</div>
          <div class="main-doc__content">
            <ul>
              <li>
                — администрация не несёт никакой ответственности за услуги,
                предоставляемые третьими лицами
              </li>
              <li>
                — в случае возникновения форс-мажорной ситуации (боевые
                действия, чрезвычайное положение, стихийное бедствие и т.д.)
                Администрация не гарантирует сохранность информации, размещённой
                Пользователем, а также бесперебойную работу информационного
                ресурса
              </li>
            </ul>
          </div>
        </div>
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">Условия действия Соглашения</div>
          <div class="main-doc__content">
            <p>Данное Соглашение вступает в силу при регистрации на сайте.</p>
            <p>
              Соглашение перестаёт действовать при появлении его новой версии.
            </p>
            <p>
              Администрация оставляет за собой право в одностороннем порядке
              изменять данное соглашение по своему усмотрению.
            </p>
            <p>
              Администрация не оповещает пользователей об изменении в
              Соглашении.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
